import { Component, OnInit, AfterViewInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GetCitiesService } from './shared/models/client-settings/get-cities.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'agenda-v2';
  public isLogged: any = false;
  public jwtHelperService = new JwtHelperService();

  constructor(
    private getCitiesService: GetCitiesService,
  ) {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.getCitiesService.resolve().then((response) => {
    });
  }


}
